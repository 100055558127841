import { useMemo, useState, useCallback, useContext } from 'react';

import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { CardContextType, ModifyCardFnc, ReplaceCardFnc } from './types';
import { Card } from '../../models';
import { fetchAllCards, fetchLicenceRights } from './card-context-api';
import { useIntl } from 'react-intl';

const getLicenceDate = (date: string) => {
  const [day, month, year] = date.split('.');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const useCards = (): CardContextType => {
  const [allCards, setAllCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();

  const blockedCards = useMemo(
    () => allCards.filter((card) => card.typeOfHolder === 'Z'),
    [allCards]
  );

  const activeCards = useMemo(
    () =>
      allCards.filter(
        (card) => card.typeOfHolder === 'O' && card.cardState === 'R'
      ),
    [allCards]
  );

  const loadCards = useCallback(async () => {
    try {
      setLoading(true);
      const { count, items } = await fetchAllCards();
      const { items: licenceRights } = await fetchLicenceRights();

      if (items && count !== undefined) {
        setAllCards(
          items.map((card) => ({
            ...card,
            newLicenceData:
              licenceRights?.find(
                (right) =>
                  right.possibleToUse &&
                  right.cardType === card.cardType &&
                  right.licenceType === card.licenceType &&
                  getLicenceDate(right.licenceRightValidTo) >
                    new Date(card.licenceValidTo)
              ) ?? undefined,
          }))
        );
      }
    } catch (err) {
      showSnackbar(
        intl.formatMessage({ id: 'VPUK__CARD_LOADING__FAILED' }),
        SnackbarVariant.ERROR
      );
    } finally {
      setLoading(false);
    }
  }, [intl, showSnackbar]);

  const replaceCard: ReplaceCardFnc = useCallback((id, newCard) => {
    setAllCards((previous) =>
      previous.map((card) => (card.id === id ? newCard : card))
    );
  }, []);

  const modifyCard: ModifyCardFnc = useCallback((id, fields) => {
    setAllCards((previous) =>
      previous.map((card) => (card.id === id ? { ...card, ...fields } : card))
    );
  }, []);

  const cardCtx = useMemo(
    () => ({
      loadCards,
      allCards,
      blockedCards,
      activeCards,
      replaceCard,
      modifyCard,
      loading,
    }),
    [
      loadCards,
      activeCards,
      blockedCards,
      allCards,
      replaceCard,
      modifyCard,
      loading,
    ]
  );

  return cardCtx;
};
