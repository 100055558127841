import { useState, useContext, useCallback } from 'react';
import { SnackbarVariant, SnackbarContext } from '@eas/common-web';

import { fetchGdprApproval } from './profile-api';
import { UserInfoContext } from '../../../components/user-info-context/user-info-context';
import { FormValues } from './profile-types';
import { useIntl } from 'react-intl';

export const useProfile = () => {
  const { userInfo, loading, reload } = useContext(UserInfoContext);
  const [processingApproval, setProcessingApproval] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();

  const handleSubmit = useCallback(
    async ({ gdpr }: FormValues) => {
      try {
        setProcessingApproval(true);
        await fetchGdprApproval(gdpr);
        showSnackbar(
          intl.formatMessage({ id: 'VPUK__PROFILE__SAVE_SUCCESS' }),
          SnackbarVariant.SUCCESS
        );
        reload();
      } catch (err) {
        showSnackbar(
          intl.formatMessage({ id: 'VPUK__PROFILE__SAVE_FAILED' }),
          SnackbarVariant.ERROR
        );
      } finally {
        setProcessingApproval(false);
      }
    },
    [showSnackbar, intl, reload]
  );

  return { userInfo, loading, handleSubmit, processingApproval };
};
