import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Card } from '../../../models';
import { FormattedMessage } from 'react-intl';

interface ExpandedCardRowProps {
  row: Card;
}

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
}));

export function AllCardsExpandedCardRow({ row }: ExpandedCardRowProps) {
  const classes = useStyles();

  return (
    <TableCell colSpan={4} className={classes.root}>
      <FormattedMessage id="VPUK__CARD" />
      {` ${row.cardType?.toUpperCase()} ${
        row.licenceType ? `(${row.licenceType})` : ''
      }`}
    </TableCell>
  );
}
