import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { FormContext, UserContext } from '@eas/common-web';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useFormErrors } from '../../../hooks/use-form-errors';
import colors from '../../../constants/colors';
import avatar from './assets/avatar.svg';
import { NewCardValues, CardOrLicence } from './new-card-types';
import { CardPreview } from '../../../components/card-preview/card-preview';
import { Me } from '../../../models';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import { imgPreview, centerAspectCrop } from './new-card-crop';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage, useIntl } from 'react-intl';

const imageSizes = {
  height: 159,
  width: 132,
};

const useStyles = makeStyles((theme) => ({
  image: {
    border: '1.5px solid rgba(223, 224, 235, 1)',
    padding: 6,
    height: imageSizes.height,
    width: imageSizes.width,
    objectFit: 'cover',
  },
  button: {
    color: 'white',
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
  list: {
    paddingInlineStart: 0,
    listStylePosition: 'inside',
  },
  photoTitle: {
    fontWeight: 700,
    color: colors.gray,
  },
  dialog: {
    padding: 15,
    textAlign: 'center',
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  dialogButton: {
    margin: '10px 0 0 auto',
    color: 'white',
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.blue,
    },
    display: 'block',
  },
  dialogClose: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  validate: () => Promise<void>;
  cardsAndLicences: CardOrLicence[];
  disabled?: boolean;
};

const aspect = 230 / 283;

export function NewCardPhoto({ validate, cardsAndLicences, disabled }: Props) {
  const [file, setFile] = useState<null | File>(null);
  const classes = useStyles();
  const formCtx = useContext<FormContext<NewCardValues>>(FormContext);
  const { getFieldError } = useFormErrors<NewCardValues>();
  const values = formCtx.getFieldValues();
  const { user } = useContext<UserContext<Me>>(UserContext);
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(avatar);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const intl = useIntl();

  const cardType = useMemo(() => {
    const card = cardsAndLicences.find((card) => card.id === values.cardId);
    if (!card) {
      return;
    }

    return 'licenceType' in card ? card.licenceType : card.cardType;
  }, [cardsAndLicences, values.cardId]);

  useEffect(() => {
    if (!file) {
      setImgSrc(avatar);
      return;
    }

    const url = URL.createObjectURL(file);
    setImgSrc(url);

    validate();

    return () => URL.revokeObjectURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFinishedCropping = (file: File | null) => {
    if (!file) {
      throw new Error('Cropping unsuccessful');
    }
    setFile(file);
    formCtx.setFieldValue('photo', file);
    setOpen(false);
    validate();
  };

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  return (
    <Grid container spacing={4} justify="space-between" alignItems="center">
      <Grid item>
        <Grid container spacing={4}>
          <Grid item>
            <img
              src={imgSrc}
              alt="VPUK__NEW_CARD__PREVIEW_"
              className={classes.image}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography component="span" className={classes.photoTitle}>
                  <FormattedMessage id="VPUK__NEW_CARD__PHOTO_TITLE" />
                </Typography>
              </Grid>
              <Grid item>
                <Dialog
                  onClose={() => {
                    setOpen(false);
                  }}
                  open={open}
                  className={classes.dialog}
                >
                  <div className={classes.dialog} id="dialogWrapper">
                    <Typography
                      component="span"
                      className={classes.dialogTitle}
                    >
                      <FormattedMessage id="VPUK__NEW_CARD__PHOTO__PLEASE_CROP" />
                      <IconButton
                        onClick={() => setOpen(false)}
                        className={classes.dialogClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Typography>
                    {!!imgSrc && (
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                      >
                        <img
                          ref={imgRef}
                          alt={intl.formatMessage({
                            id: 'VPUK__NEW_CARD__PHOTO__CROPPING_IN_PROGRESS',
                          })}
                          src={imgSrc}
                          onLoad={onImageLoad}
                        />
                      </ReactCrop>
                    )}
                    <Button
                      variant="contained"
                      className={classes.dialogButton}
                      onClick={async () => {
                        const img = await imgPreview(
                          imgRef.current,
                          completedCrop
                        );
                        handleFinishedCropping(img);
                      }}
                    >
                      <FormattedMessage id="VPUK__SAVE" />
                    </Button>
                  </div>
                </Dialog>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  component="label"
                  disabled={disabled}
                >
                  <FormattedMessage id="VPUK__NEW_CARD__UPLOAD_PHOTO_BUTTON" />
                  <input
                    name="photo"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      onSelectFile(e);
                      setOpen(true);
                      // clearing input (image stays saved), allows user to close the dialog and upload same picture again
                      e.target.value = '';
                    }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <FormHelperText error>{getFieldError('photo')}</FormHelperText>
              </Grid>
              <Grid item>
                <ul className={classes.list}>
                  <li>
                    <FormattedMessage id="VPUK__NEW_CARD__PHOTO__MIN_SIZE" />
                  </li>
                  <li>
                    <FormattedMessage id="VPUK__NEW_CARD__PHOTO__MAX_SIZE" />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CardPreview
          imageSrc={imgSrc}
          cardType={cardType}
          name={
            user?.name ??
            intl.formatMessage({ id: 'VPUK__NEW_CARD__UNKNOWN_USER' })
          }
        />
      </Grid>
    </Grid>
  );
}
