import React from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import { Table } from '../../../components/table/table';
import { TableColumn } from '../../../components/table/table-types';
import { useMyFiles } from './my-files-hook';
import { Backend } from '../../../api/endpoints';
import { useIntl, FormattedMessage } from 'react-intl';

const fileColumns: TableColumn<Backend.BillDetailDto>[] = [
  {
    id: 'name',
    label: 'VPUK__MY_FILES__COLUMNS__TYPE',
  },
  {
    id: 'created',
    label: 'VPUK__MY_FILES__COLUMNS__DATE',
    valueSelector: ({ row }) => new Date(row.created).toLocaleString(),
  },
  {
    id: 'fileId',
    label: '',
    align: 'right',
    CellComponent: function DownloadButton({ data }) {
      return (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GetAppIcon />}
          href={`/api/prukazy/files/${data}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="VPUK__MY_FILES__DOWNLOAD_PDF" />
        </Button>
      );
    },
  },
];

export function MyFiles() {
  const { data, loading } = useMyFiles();
  const intl = useIntl();

  return (
    <Table<Backend.BillDetailDto>
      title={intl.formatMessage({ id: 'VPUK__MY_FILES__TITLE' })}
      columns={fileColumns.map((column) => ({
        ...column,
        label: column.label ? intl.formatMessage({ id: column.label }) : '',
      }))}
      rows={data}
      count={data.length}
      loading={loading}
    />
  );
}
