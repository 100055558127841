import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: '#fafafa',
  },
}));

export function Footer() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <footer className={classes.root}>
      <div>
        <a
          href="https://cuni.cz/UK-5360-version1-obchodni_podminky_karty.pdf"
          title={intl.formatMessage({ id: 'VPUK__FOOTER__CONDITIONS' })}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="VPUK__FOOTER__CONDITIONS" />
        </a>
      </div>
      <div>
        <a
          href="https://cuni.cz/UK-3249.html"
          title={intl.formatMessage({ id: 'VPUK__FOOTER__COLLECTION_POINTS' })}
        >
          <FormattedMessage id="VPUK__FOOTER__COLLECTION_POINTS" />
        </a>
      </div>
      <div>
        <FormattedMessage id="VPUK__FOOTER__WEB" />
        :&nbsp;
        <a
          href="https://prukazy.cuni.cz"
          title={intl.formatMessage({ id: 'VPUK__FOOTER__WEB' })}
        >
          https://prukazy.cuni.cz
        </a>
      </div>
      <div>
        <FormattedMessage id="VPUK__FOOTER__CONTACT" />
        :&nbsp;
        <a
          href="https://servicedesk.cuni.cz/marketplace/formcreator/front/formdisplay.php?id=1004"
          title={intl.formatMessage({ id: 'VPUK__FOOTER_SERVICE_DESK' })}
        >
          <FormattedMessage id="VPUK__FOOTER_SERVICE_DESK" />
        </a>
      </div>
    </footer>
  );
}
