import React, { useContext, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { Table } from '../../../components/table/table';
import {
  TableColumn,
  TableAction,
} from '../../../components/table/table-types';
import { Card } from '../../../models';
import { CardStateCell } from './my-cards-state-cell';
import { CardDetails } from './row-detail/card-details';
import { AllCardsExpandedCardRow } from './my-cards-expanded-row';
import { DateCell } from '../../../components/table/table-cells/table-date-cell';
import { CardContext } from '../../../components/card-context/card-context';
import { CardTypeOfHolderCell } from './my-cards-type-of-holder-cell';
import { useIntl } from 'react-intl';

const myCardsColumns: TableColumn<Card>[] = [
  {
    id: 'licenceNumber',
    label: 'VPUK__MY_CARDS__COLUMNS__LICENCE_NUMBER',
  },
  {
    id: 'licenceValidTo',
    label: 'VPUK__MY_CARDS__COLUMNS__LICENCE_VALID_TO',
    CellComponent: DateCell,
  },
  {
    id: 'cardType',
    label: 'VPUK__MY_CARDS__COLUMNS__CARD_TYPE',
  },
  {
    id: 'cardState',
    label: 'VPUK__MY_CARDS__COLUMNS__CARD_STATE',
    CellComponent: CardStateCell,
  },

  {
    id: 'typeOfHolder',
    label: 'VPUK__MY_CARDS__COLUMNS__TYPE_OF_HOLDER',
    CellComponent: CardTypeOfHolderCell,
  },
];

const actions: TableAction[] = [
  {
    label: 'VPUK__MY_CARDS__ACTIONS__ORDER',
    callback: (push) => push('/portal/prukazy/novy'),
    Icon: <AddIcon />,
  },
];

export function MyCards() {
  const {
    loadCards,
    allCards,
    blockedCards,
    activeCards,
    loading,
  } = useContext(CardContext);
  const { path } = useRouteMatch();
  const intl = useIntl();

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const CardTableFactory = useCallback(
    (title: string, cards: Card[]) => (
      <Table<Card>
        columns={myCardsColumns.map((column) => ({
          ...column,
          label: intl.formatMessage({ id: column.label }),
        }))}
        expandableRows
        title={title}
        actions={actions.map((action) => ({
          ...action,
          label: intl.formatMessage({ id: action.label }),
        }))}
        DetailComponent={CardDetails}
        ExpandedComponent={AllCardsExpandedCardRow}
        rows={cards}
        count={cards.length}
        loading={loading}
      />
    ),
    [intl, loading]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Switch>
          <Route exact path={path}>
            {CardTableFactory(
              intl.formatMessage({ id: 'VPUK__NAV__ALL_CARDS' }),
              allCards
            )}
          </Route>
          <Route exact path={`${path}/aktivni`}>
            {CardTableFactory(
              intl.formatMessage({ id: 'VPUK__NAV__ACTIVE_CARDS' }),
              activeCards
            )}
          </Route>
          <Route exact path={`${path}/blokovane`}>
            {CardTableFactory(
              intl.formatMessage({ id: 'VPUK__NAV__BLOCKED_CARDS' }),
              blockedCards
            )}
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}
