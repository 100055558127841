import React from 'react';

import { formFieldFactory } from '@eas/common-web';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { EmptyWrapper } from '../empty-wrapper/empty-wrapper';

type Props = SelectProps & {
  onChange: (value: string | null) => void;
  validate?: () => Promise<void>;
};

export function Select({ onChange, validate, children, ...otherProps }: Props) {
  return (
    <MuiSelect
      onChange={(e) => {
        onChange(e.target.value as string);
        // Make validation appear also on change (not only blur), but onChange() is delayed
        if (validate) setTimeout(() => validate(), 200);
      }}
      {...otherProps}
    >
      {children}
    </MuiSelect>
  );
}

export const FormFieldSelect = formFieldFactory(Select, EmptyWrapper);
