import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Form, FormSubmitButton } from '@eas/common-web';
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../constants/colors';
import { ProfileItem } from './profile-item';
import { Page } from '../../../components/page/page';
import { FormValues } from './profile-types';
import { useProfile } from './use-profile-hook';
import { FormFieldCheckbox } from '../../../components/form/checkbox/checkbox';
import { useIntl, FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
}));

export const Profile = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { userInfo, loading, handleSubmit, processingApproval } = useProfile();

  const address =
    userInfo?.city && userInfo?.street
      ? `${userInfo?.city}, ${userInfo?.street}`
      : undefined;

  const fullName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo?.firstName} ${userInfo?.lastName}`
      : undefined;

  const initialValues: FormValues = { gdpr: userInfo?.escApproval ?? false };

  return (
    <Page>
      <Page.Section>
        <Page.Header
          title={intl.formatMessage({ id: 'VPUK__PROFILE__TITLE' })}
        />
        {loading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={2}>
            <ProfileItem
              label={`${intl.formatMessage({ id: 'VPUK__PROFILE__NAME' })}: `}
              content={fullName}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__PERSON_NUMBER',
              })}: `}
              content={userInfo?.personNumber}
            />
            <ProfileItem
              label={`${intl.formatMessage({ id: 'VPUK__PROFILE__EMAIL' })}: `}
              content={(userInfo?.emails ?? []).join(', ')}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__ADDRESS',
              })}: `}
              content={address}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__COUNTRY',
              })}: `}
              content={userInfo?.country}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__BIRTH_DATE',
              })}: `}
              content={userInfo?.birthDate}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__PERSONAL_IDENTIFICATION_NUMBER',
              })}: `}
              content={userInfo?.personalIdentificationNumber}
            />
            <ProfileItem
              label={`${intl.formatMessage({
                id: 'VPUK__PROFILE__LDAP_PASSWORD_VALID_UNTIL',
              })}: `}
              content={userInfo?.ldapPasswordValidUntil}
            />
          </Grid>
        )}
      </Page.Section>
      <Divider />
      {!loading && (
        <Page.Section>
          <Form initialValues={initialValues} editing onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2}>
              <Grid item container alignItems="center">
                <Grid item>
                  <FormControlLabel
                    control={<FormFieldCheckbox name="gdpr" />}
                    disabled={processingApproval}
                    label={intl.formatMessage({
                      id: 'VPUK__PROFILE__AGREE_WITH_SENDING_INFO',
                    })}
                  />
                </Grid>
                <Grid item>
                  <a
                    href="https://cuni.cz/UK-5360-version1-informace_ke_zpracovani_osobnich_udaju.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage id="VPUK__PROFILE__GDPR_INFO_FILENAME" />
                  </a>
                </Grid>
              </Grid>
              <Grid item>
                <FormSubmitButton
                  variant="contained"
                  type="submit"
                  className={classes.button}
                  disabled={processingApproval}
                >
                  <FormattedMessage id="VPUK__PROFILE__SEND" />
                </FormSubmitButton>
              </Grid>
            </Grid>
          </Form>
        </Page.Section>
      )}
    </Page>
  );
};
