import { FormContext } from '@eas/common-web';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

export const useFormErrors = <T extends Record<string, any>>() => {
  const formCtx = useContext<FormContext<T>>(FormContext);
  const intl = useIntl();

  const errors = formCtx.errors.reduce(
    (obj, error) => ({
      ...obj,
      [error.key]: error.value,
    }),
    {}
  ) as { [K in keyof T]?: string };

  const getFieldError = (key: keyof T) =>
    errors[key] ? intl.formatMessage({ id: errors[key] }) : '';

  return { getFieldError };
};
