import React from 'react';

import { Card } from '../../../models';
import { FormattedMessage } from 'react-intl';

interface CardStateCellProps {
  data: Card['cardState'];
  allData: Card;
}

const textMapping: Record<Card['cardState'], string> = {
  N: 'VPUK__MY_CARDS__CARD_STATE__N',
  S: 'VPUK__MY_CARDS__CARD_STATE__S',
  R: 'VPUK__MY_CARDS__CARD_STATE__R',
  V: 'VPUK__MY_CARDS__CARD_STATE__V',
};

export const CardStateCell = ({ data }: CardStateCellProps) => (
  <span>
    <FormattedMessage
      id={textMapping[data] ?? 'VPUK__MY_CARDS__CARD_STATE__UNKNOWN'}
    />
  </span>
);
