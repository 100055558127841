import * as yup from 'yup';

import { NewCardValues } from './new-card-types';

const GDPR_MESSAGE = 'VPUK__NEW_CARD__VALIDATION__GRPR_REQUIRED';

export const newCardValidationSchema = yup.object<NewCardValues>().shape({
  photo: yup
    .mixed<File>()
    .required('VPUK__NEW_CARD__VALIDATION__PHOTO_REQUIRED'),
  cardId: yup.string().required('VPUK__NEW_CARD__VALIDATION__CARD_ID_REQUIRED'),
  pickupWorkplace: yup
    .string()
    .required('VPUK__NEW_CARD__VALIDATION__PICKUP_WORKPLACE_REQUIRED'),
  gdpr: yup.boolean().required(GDPR_MESSAGE).oneOf([true], GDPR_MESSAGE),
  email: yup
    .string()
    .email('VPUK__NEW_CARD__VALIDATION__EMAIL_FORMAT')
    .required('VPUK__NEW_CARD__VALIDATION__EMAIL_REQUIRED'),
});
