import React from 'react';

import { formFieldFactory } from '@eas/common-web';
import { EmptyWrapper } from '../empty-wrapper/empty-wrapper';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';

type Props = CheckboxProps & {
  onChange: (value: boolean | null) => void;
  value?: boolean | null;
  validate?: () => Promise<void>;
};

export function Checkbox({ onChange, value, validate, ...otherProps }: Props) {
  const checked = value ?? false;

  return (
    <MuiCheckbox
      onChange={(e) => {
        onChange(e.target.checked);
        if (validate) setTimeout(() => validate(), 200);
      }}
      checked={checked}
      {...otherProps}
    />
  );
}

export const FormFieldCheckbox = formFieldFactory(Checkbox, EmptyWrapper);
