import React from 'react';
import { useEffect, useState, useContext, useCallback } from 'react';
import { SnackbarVariant, SnackbarContext } from '@eas/common-web';

import { UserInfoContextType } from './types';
import { fetchUserInfo } from './user-info-context-api';
import { Backend } from '../../api/endpoints';
import { FormattedMessage } from 'react-intl';

export const useUserInfo = (): UserInfoContextType => {
  const [userInfo, setUserInfo] = useState<Backend.PersonInformationDto>();
  const [loading, setLoading] = useState(true);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetchInfo = useCallback(async () => {
    setLoading(true);
    try {
      const userInfo = await fetchUserInfo();
      setUserInfo(userInfo);
    } catch (err) {
      showSnackbar(
        <FormattedMessage id="VPUK__USER_INFO__LOADING_FAILED" />,
        SnackbarVariant.ERROR
      );
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return { userInfo, loading, reload: fetchInfo };
};
