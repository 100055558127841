import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Backend } from '../../api/endpoints';
import { useUserInfo } from '../user-info-context';
import { CardStylesProps } from './types';
import { getCardStyles } from './card-styles';
import JsBarcode from 'jsbarcode';
import { useIntl } from 'react-intl';

const DEAFULT_FONT_SIZE = 12;

const useStyles = makeStyles<Theme, CardStylesProps>(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  cardImage: {
    height: 'auto',
    width: 275,
    borderRadius: 10,
    boxShadow: '0 0 7px lightgray',
  },
  photo: {
    position: 'absolute',
    top: (props) => props?.image?.top ?? 29,
    right: (props) => props?.image?.right ?? 14,
    height: 90,
    width: 76,
    objectFit: 'cover',
  },
  name: {
    position: 'absolute',
    bottom: (props) => props?.name?.bottom ?? 51,
    left: (props) => props?.name?.left ?? 8,
    fontSize: (props) => props?.fontSize ?? DEAFULT_FONT_SIZE,
  },
  validTo: {
    position: 'absolute',
    bottom: (props) => props?.validTo?.bottom ?? 9,
    left: (props) => props?.validTo?.left ?? 8,
    fontSize: (props) => props?.fontSize ?? DEAFULT_FONT_SIZE,
    display: (props) => props?.validTo?.display,
  },
  birthDate: {
    position: 'absolute',
    bottom: (props) => props?.birthDate?.bottom ?? 30,
    left: (props) => props?.birthDate?.left ?? 8,
    fontSize: (props) => props?.fontSize ?? DEAFULT_FONT_SIZE,
    display: (props) => props?.birthDate?.display,
  },
  personNumber: {
    position: 'absolute',
    bottom: (props) => props?.personNumber?.bottom ?? 30,
    left: (props) => props?.personNumber?.left ?? 90,
    fontSize: (props) => props?.fontSize ?? DEAFULT_FONT_SIZE,
    display: (props) => props?.personNumber?.display,
  },
  barcode: {
    position: 'absolute',
    bottom: (props) => props?.barcode?.bottom ?? -5,
    left: (props) => props?.barcode?.left ?? 30,
    fontSize: (props) => props?.fontSize ?? DEAFULT_FONT_SIZE,
    display: (props) => props?.barcode?.display ?? 'none',
  },
  barcodeSvg: {
    width: 150,
  },
}));

interface Props {
  imageSrc?: string;
  cardType?: Backend.CardType;
  licenceType?: Backend.LicenceType;
  name?: string;
  validTo?: string | null;
  barcode?: string;
}

export const CardPreview = ({
  imageSrc,
  cardType,
  licenceType,
  name,
  validTo,
  barcode,
}: Props) => {
  const { cardPath, cardStyles } = getCardStyles(cardType, licenceType);
  const classes = useStyles(cardStyles ?? {});
  const { userInfo } = useUserInfo();
  const intl = useIntl();

  useEffect(() => {
    const barcodeElem = document.getElementById('barcode');
    if (cardStyles?.barcode && barcodeElem)
      JsBarcode('#barcode', barcode ?? '', {
        height: 40,
        format: 'CODE39',
        fontSize: 12,
        margin: 0,
      });
  });

  if (!cardPath) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <img
        src={cardPath}
        alt={intl.formatMessage({ id: 'VPUK__CARD' })}
        className={classes.cardImage}
      />
      {imageSrc && (
        <img
          src={imageSrc}
          alt={intl.formatMessage({
            id: 'VPUK__NEW_CARD__PREVIEW_UPLOADED_PHOTO',
          })}
          className={classes.photo}
        />
      )}
      {name && <span className={classes.name}>{name}</span>}
      {validTo && <span className={classes.validTo}>{validTo}</span>}
      {userInfo?.birthDate && (
        <span className={classes.birthDate}>{userInfo.birthDate}</span>
      )}
      {userInfo?.personNumber && (
        <span className={classes.personNumber}>{userInfo.personNumber}</span>
      )}
      {barcode && (
        <span className={classes.barcode}>
          <svg id="barcode" className={classes.barcodeSvg}></svg>
        </span>
      )}
    </div>
  );
};
