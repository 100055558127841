import React, { ChangeEvent, useCallback } from 'react';

import { formFieldFactory } from '@eas/common-web';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { EmptyWrapper } from '../empty-wrapper/empty-wrapper';

type Props = TextFieldProps & {
  onChange: (value: string) => void;
  validate?: () => Promise<void>;
};

export function TextField({
  onChange,
  validate,
  children,
  ...otherProps
}: Props) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (validate) {
        // Make validation appear also on change (not only blur), but onChange() is delayed
        setTimeout(() => validate(), 200);
      }
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <MuiTextField onChange={handleChange} {...otherProps}>
      {children}
    </MuiTextField>
  );
}

export const FormTextField = formFieldFactory(TextField, EmptyWrapper);
