import React from 'react';

import { Card } from '../../../models';
import { FormattedMessage } from 'react-intl';

interface CardTypeOfHolderCellProps {
  data: Card['typeOfHolder'];
  allData: Card;
}

export const typeOfHolderMapping: Record<Card['typeOfHolder'], string> = {
  C: 'VPUK__MY_CARDS__TYPE_OF_HOLDER__C',
  O: 'VPUK__MY_CARDS__TYPE_OF_HOLDER__O',
  ORG: 'VPUK__MY_CARDS__TYPE_OF_HOLDER__ORG',
  Z: 'VPUK__MY_CARDS__TYPE_OF_HOLDER__Z',
};

export const CardTypeOfHolderCell = ({ data }: CardTypeOfHolderCellProps) => (
  <span>
    <FormattedMessage
      id={
        typeOfHolderMapping[data] ?? 'VPUK__MY_CARDS__TYPE_OF_HOLDER__UNKNOWN'
      }
    />
  </span>
);
