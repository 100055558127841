import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { LocaleContext, LocaleName, useEventCallback } from '@eas/common-web';
import makeStyles from '@material-ui/core/styles/makeStyles';
import czFlag from '../../assets/cz.svg';
import enFlag from '../../assets/en.svg';

const useStyles = makeStyles(() => ({
  language: {
    minWidth: 'auto',
  },
}));

export function LanguageSwitcher() {
  const { locale, switchLocale } = useContext(LocaleContext);

  const handleLanguageSwitch = useEventCallback(() => {
    switchLocale(locale.name === LocaleName.cs ? LocaleName.en : LocaleName.cs);
  });

  const classes = useStyles();

  return (
    <Button
      size="small"
      disableElevation
      className={classes.language}
      onClick={handleLanguageSwitch}
      title={
        locale.name === LocaleName.cs
          ? 'Switch to English'
          : 'Přepnout do češtiny'
      }
    >
      <img
        src={locale.name === LocaleName.cs ? enFlag : czFlag}
        alt={locale.name === LocaleName.cs ? 'EN' : 'CZ'}
        width="40"
      />
    </Button>
  );
}
